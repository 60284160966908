













  import { Component, Vue } from 'nuxt-property-decorator'
  import { BNavItemDropdown } from 'bootstrap-vue/src/components/nav'

  @Component({
    components: {
      SidebarAccount: () => import('~/pages/account/SidebarAccount.vue'),
    },
  })
  export default class AccountDropdownComponent extends Vue {
    closeDropdown() {
      (this.$refs.navDropdown as BNavItemDropdown).hide()
    }
  }
